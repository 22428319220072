import { Editor, Point, Range, Element as SlateElement, Text, Transforms } from 'slate'

import { createLinkNode, isNodeEmpty, isUrl } from '../utils'
import { insertExitBreak } from './with-custom-break'

const shouldPerformLinkCheck = (editor: Editor) => {
  if (!editor.selection) return false

  if (!Range.isCollapsed(editor.selection)) return false

  const startPointOfLastCharacter = Editor.before(editor, editor.selection, {
    unit: 'character'
  }) as Point

  if (!startPointOfLastCharacter) return false

  const [node] = Editor.parent(editor, editor.selection)

  // if we are already inside a link, exit early.
  if (SlateElement.isElement(node) && node.type === 'link') {
    return false
  }

  const [currentNode] = Editor.node(editor, editor.selection)

  // if we are not inside a text node, exit early.
  if (!Text.isText(currentNode)) return false

  return true
}

const scanLastWordForLink = (editor: Editor, endPointOfWord: Point) => {
  let end = endPointOfWord
  let start = Editor.before(editor, end, {
    unit: 'character'
  }) as Point
  if (!start || !end) return
  // If last char is a comma or dot, dont consider them as part of the link & move a step back
  const lastCharacter = Editor.string(editor, Editor.range(editor, start, end))
  if (lastCharacter === ',' || lastCharacter === '.') {
    start = Editor.before(editor, start, {
      unit: 'character'
    }) as Point
    endPointOfWord = Editor.before(editor, endPointOfWord, {
      unit: 'character'
    }) as Point
    end = endPointOfWord
  }

  const startOfTextNode = Editor.point(editor, endPointOfWord.path, {
    edge: 'start'
  })

  while (
    start &&
    Editor.string(editor, Editor.range(editor, start, end)) !== ' ' &&
    !Point.isBefore(start, startOfTextNode)
  ) {
    end = start
    start = Editor.before(editor, end, { unit: 'character' }) as Point
  }

  const lastWordRange = Editor.range(editor, end, endPointOfWord)
  const lastWord = Editor.string(editor, lastWordRange)
  if (isUrl(lastWord)) {
    convertToLink(editor, lastWord, lastWordRange)
  }
}

const convertToLink = (editor: Editor, lastWord: string, lastWordRange: Range) => {
  Promise.resolve().then(() => {
    Transforms.wrapNodes(editor, createLinkNode(lastWord, lastWord), { split: true, at: lastWordRange })
  })
}

export const withLinks = (editor: Editor) => {
  const { isInline, onChange, insertData, insertText, insertBreak, normalizeNode } = editor

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element)
  }

  // remove links that have no text or no href
  editor.normalizeNode = entry => {
    const [node, path] = entry
    if (SlateElement.isElement(node) && node.type === 'link') {
      if (!node.url || isNodeEmpty(editor, node)) {
        Transforms.removeNodes(editor, { at: path })
      }
    }
    normalizeNode(entry)
  }

  // If pressing enter with cursor within a link, link should be removed after cursor
  editor.insertBreak = () => {
    const { selection } = editor
    if (!selection) return

    const [linkElement] = Editor.nodes(editor, {
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link'
    })
    if (linkElement) {
      Editor.withoutNormalizing(editor, () => {
        insertExitBreak(editor)
        Transforms.unwrapNodes(editor, {
          match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link'
        })
      })

      return
    }
    insertBreak()
  }

  editor.onChange = options => {
    // If pressing enter, also perform the link check at the point the pressed it
    // Note could also do this via insertBreak, but this gives us a path to prev para
    if (options && options.operation?.type === 'split_node') {
      const endOfLastParagraph = { path: options.operation?.path, offset: options.operation?.position } as Point
      scanLastWordForLink(editor, endOfLastParagraph)
    }
    onChange(options)
  }

  editor.insertText = text => {
    if (editor.selection && shouldPerformLinkCheck(editor)) {
      const [cursorPoint] = Range.edges(editor.selection)

      if (text.endsWith(' ')) {
        scanLastWordForLink(editor, cursorPoint)
      }
    }

    insertText(text)
  }

  // Note: slate's plugin architecture relies on plugins being called in a specific order
  // So need to make sure this runs before any other paste processing
  // Note: could extend this to scan for any links within 'text' and convert all

  editor.insertData = data => {
    const urlText = data.getData('text/plain')
    if (isUrl(urlText)) {
      const { selection } = editor
      if (!selection) {
        return
      }

      const [currentNode, _] = Editor.node(editor, selection)
      if (!Text.isText(currentNode)) {
        return
      }

      if (Range.isCollapsed(selection)) {
        // Point is selected, use the link as its own label, insert empty text node after to exit the link
        Transforms.insertNodes(editor, [createLinkNode(urlText, urlText), { text: ' ' }], { select: true })
      } else {
        // Range is selected, use the highlighted text as the label
        Transforms.wrapNodes(editor, createLinkNode(urlText, currentNode.text), { split: true, at: selection })
        // Then collapse the section and move cursor to end of word(s)
        Transforms.collapse(editor, { edge: 'end' })
        // Make sure the cursor is out of the link so user can continue typing
        Transforms.move(editor, { unit: 'offset' })
      }

      return
    }

    insertData(data)
  }

  return editor
}
