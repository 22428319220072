import { memo, useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import { ReactEditor, useReadOnly, useSlate, useSlateStatic } from 'slate-react'
import { Editor, Element, Text, Transforms } from 'slate'
import styled from 'styled-components/macro'

import { Box } from '../../../layout'
import { LinkElement } from '../text-editor-types'
import { themeColor, themeElevation } from '../../../theme'
import { isBlockActive, removeLink } from '../utils'
import { IconButton } from '../../../button'
import { TextInput } from '../../text-input'
import { Tooltip } from '../../../overlays/tooltip'

const isSelectionWithinTextNode = (editor: Editor) => {
  const { selection } = editor
  const [linkTextNode] = selection ? Editor.node(editor, selection) : []

  if (selection && !!linkTextNode && Text.isText(linkTextNode) && selection.focus.offset !== linkTextNode.text.length) {
    return true
  }
  return false
}

export const LinkEditor = () => {
  // needs non-static editor to monitor actual selction change when clicking to a position.
  // but using that editor throughout will cause a ton of issues and infinite loops, so this
  // is pulled out 1 level and the inner component using the status editor is memoized and
  // updates when the underlying dom link node changes and is present.
  const editor = useSlate()
  const [linkEl, setLinkEl] = useState<HTMLAnchorElement | null>(null)
  const [path, setPath] = useState<any>(null)

  useEffect(() => {
    const isLinkActive = isBlockActive(editor, 'link') && isSelectionWithinTextNode(editor)
    if (!isLinkActive) {
      setLinkEl(null)
      setPath(null)
      return
    }

    const linkParent = Editor.above<LinkElement>(editor, {
      match: n => Element.isElement(n) && n.type === 'link'
    })

    if (!linkParent) return

    const [linkNode, path] = linkParent

    const domEl = ReactEditor.toDOMNode(editor, linkNode)

    setLinkEl(domEl as HTMLAnchorElement)
    setPath(path)
  }, [editor.selection])

  return linkEl ? <LinkEditorInner linkEl={linkEl} setLinkEl={setLinkEl} path={path} /> : null
}

const LinkEditorInner = memo(
  ({ linkEl, path }: { linkEl: HTMLAnchorElement; setLinkEl?: any; path: any }) => {
    const editor = useSlateStatic()
    const [popoverRef, setPopoverRef] = useState<HTMLElement | null>(null)
    const [showEditInput, setShowEditInput] = useState(false)

    const {
      styles,
      attributes,
      update: updatePopper
    } = usePopper(linkEl, popoverRef, {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 4]
          }
        },
        {
          name: 'preventOverflow',
          options: {
            padding: 4
          }
        },
        {
          name: 'flip',
          options: {
            padding: 4
          }
        }
      ]
    })

    const handleLinkClick = () => {
      window.open(linkEl.href, '_newtab')
    }

    const updateLink = (link: string) => {
      Transforms.setNodes(editor, { url: link }, { at: path })
      setShowEditInput(false)
      ReactEditor.focus(editor)
    }

    const unLink = () => {
      removeLink(editor)
      ReactEditor.focus(editor)
    }

    return (
      <LinkPopopver ref={setPopoverRef} style={styles.popper} {...attributes.popper}>
        {showEditInput ? (
          <EditLinkInput
            onSubmit={link => {
              updateLink(link)
              updatePopper?.()
            }}
            onCancelEdit={() => setShowEditInput(false)}
            link={linkEl.href}
          />
        ) : (
          <Box direction="row" gap="4px" align="center" width="100%">
            {/* Hidden icon button to fix stupid focus issue where leftmost thing auto focuses */}
            <IconButton
              disableTooltip
              data-roving-tabindex-ignore="true"
              label="dummy"
              icon="add"
              css="display: none;"
            />

            <Box
              css={`
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                flex-grow: 1;
              `}
            >
              <StyledLink href={linkEl.href} onClick={handleLinkClick} rel="noreferrer" target="_blank">
                {linkEl.href}
              </StyledLink>
            </Box>
            <IconButton
              size="medium"
              icon="edit"
              label="Edit link"
              disableTooltip
              onClick={() => setShowEditInput(true)}
            />
            <IconButton size="medium" icon="unlink" label="Remove link" disableTooltip onClick={unLink} />
          </Box>
        )}
      </LinkPopopver>
    )
  },
  (a, b) => a.linkEl === b.linkEl
)

export const Link = ({ attributes, children, element }: any) => {
  const readOnly = useReadOnly()

  return readOnly ? (
    <Tooltip content={element.url} placement="top" disableOnClickAsTriggerProp>
      <StyledLink href={element.url} target="_blank" {...attributes}>
        {children}
      </StyledLink>
    </Tooltip>
  ) : (
    <StyledLink href={element.url} target="_blank" {...attributes}>
      {children}
    </StyledLink>
  )
}

type EditLinkInputProps = {
  onSubmit: (name: string) => void
  onCancelEdit: () => void
  link: string
}

const EditLinkInput = ({ onSubmit, onCancelEdit, link }: EditLinkInputProps) => {
  const [inputValue, setInputValue] = useState(link)
  const clickSave = () => {
    onSubmit(inputValue)
  }

  return (
    <Box width="100%" align="center" direction="row" gap="4px">
      {/* Hidden icon button to fix stupid focus issue where leftmost thing auto focuses */}
      <IconButton disableTooltip data-roving-tabindex-ignore="true" label="dummy" icon="add" css="display: none;" />
      <IconButton size="medium" icon="arrow-back" label="Cancel" disableTooltip onClick={onCancelEdit} />
      <InlineTextInput
        ref={ref => ref && ref.focus()}
        defaultValue={link}
        onChange={event => setInputValue(event.target.value)}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            clickSave()
          }
        }}
      />
      <IconButton primary size="medium" icon="check" label="Save" disableTooltip onClick={clickSave} />
    </Box>
  )
}

const InlineTextInput = styled(TextInput).attrs({ plain: true })`
  flex-grow: 1;
  height: 32px;
  outline: none !important;
  border-radius: 8px;
  background-color: ${themeColor('bg-1')};
`

const StyledLink = styled.a`
  color: ${themeColor('primary')};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const LinkPopopver = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 100;
  white-space: nowrap;
  userselect: 'none';
  border-radius: 8px;
  border: none;
  width: auto;
  box-shadow: ${themeElevation('medium')};
  background: ${themeColor('menu-bg')};
  padding: 6px 8px;
  width: 280px;
  line-height: 1rem;
`
